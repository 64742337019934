<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="articles-inventory-list"
                          base-table="article"
                          columnsPrefix="article.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :tbody-tr-class="rowClass"
                          title="article.title.articles_inventory_list"
                >
                    <template v-slot:top-actions>
                        <b-form inline class="mb-2">
                            <date-picker v-model="date"
                                         class="mr-2"
                                         :config="config"
                            />

                            <b-button @click="resetDate" variant="warning">{{ $t('common.button.reset') }}</b-button>
                        </b-form>
                    </template>

                    <template #cell(stocks_info)="row">
                        <div v-for="(sum, warehouse) in row.item.stocks_info.warehouses">{{ warehouse }}:
                            <b>{{ sum }}</b></div>
                        <br>
                        <div>{{ $t('article.text.total') }}: <b>{{ row.item.stocks_info.total }}</b></div>
                    </template>

                    <template #cell(stock_sum)="row">
                        {{ row.item.purchase_price * row.item.stocks_info.total | priceFormat }} &euro;
                    </template>

                    <template #cell(actions)="row">
                        <b-button
                            @click="openArticle(row.item)"
                            v-if="$root.hasAccess('articles','update')"
                            size="sm"
                            variant="info"
                            class="mr-2"
                            :title="$t('common.title.edit')"
                        >
                            <font-awesome-icon icon="edit"/>
                        </b-button>

                        <b-button @click="deleteArticle(row.item.id)"
                                  v-if="$root.hasAccess('articles','delete')"
                                  size="sm"
                                  variant="danger"
                                  :title="$t('common.title.delete')"
                        >
                            <font-awesome-icon icon="trash"/>
                        </b-button>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import moment from "moment";

export default {
    name: 'ArticlesInventoryList',
    mixins: [commonTable],
    data() {
        return {
            date: moment().format('DD.MM.YYYY'),
            saveFilters: true,
            config: {
                format: 'DD.MM.YYYY',
                useCurrent: false,
                showClear: true,
                locale: 'de',
                keyBinds: {
                    right: null,
                    left: null,
                    delete: null,
                }
            }
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticlesInventoryListTable']),
        ...mapGetters('CommonData', ['getModules', 'getSettings']),
        resetDate() {
            this.date = moment().format('DD.MM.YYYY')
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Articles/fetchArticlesInventoryList', 'getArticlesInventoryListTable');
        },
        ctxBaseParams() {
            return {
                'date': this.date
            }
        },
        deleteArticle(id) {
            this.$removeConfirm('Articles/deleteArticle', id, this.refreshTable)
        },
        openArticle(item) {
            this.$root.$children[0].openModal('article-modal', {
                id: item ? item.id : 0
            }, this.refreshTable)
        },
        rowClass(item) {
            if (item) {
                var row_class = '';
                for (let stock of item.stocks) {
                    if (stock.items_cnt_avail <= stock.warning_lvl) {
                        var row_class = 'table-danger';
                        break;
                    }
                }
                return row_class;
            }
        },
    },
    watch: {
        date: function (val) {
            this.$refs.table.refresh()
        },
    }
}
</script>